import React from 'react'
import { Grid, Box, Modal, Typography, Button, IconButton } from '@mui/material'
import { Paper, Card, CardHeader, CardContent, useTheme } from '@mui/material'
import { InfoOutlined, ErrorOutline, WarningAmber, CheckCircleOutline, NoBackpackSharp, Clear } from '@mui/icons-material'

var bgColor = null
var icon = null

const ApiStatusModal = (props) => {
  const [open, setOpen] = React.useState(true)
  //const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    props.setMessage ? props.setMessage() : ''
  }
  const theme = useTheme()

  switch (props.status) {
    case 'Error':
      icon = <ErrorOutline sx={{ mb: -0.625 }} />
      bgColor = theme.palette.error.main
      break
    case 'Info':
      icon = <InfoOutlined sx={{ mb: -0.625 }} />
      bgColor = theme.palette.mode == 'light' ? theme.palette.primary[200] : theme.palette.primary.dark
      break
    case 'Warning':
      icon = <WarningAmber sx={{ mb: -0.625 }} />
      bgColor = theme.palette.warning.dark
      break
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box display="flex">
          <Paper
            elevation={4}
            sx={{
              position: 'absolute',
              top: '15%',
              left: { xs: '9%', sm: '30%', md: '40%' },
              zIndex: 3,
              m: 'auto',
              mt: 22,
              alignContent: 'center',
              height: 100,
              width: 320,
            }}>
            <Card
              sx={{
                border: 'none',
              }}>
              <CardHeader
                sx={{
                  padding: '12px',
                  background: bgColor,
                  '& .MuiCardHeader-action': { mr: 0 },
                }}
                title={
                  <>
                    <Grid container>
                      <Grid item xs={6}>
                        {icon} {props.status}
                      </Grid>
                      <Grid item xs={6} sx={{ mt: '-3px' }} textAlign={'end'}>
                        <IconButton sx={{ p: 0 }} onClick={handleClose}>
                          <Clear sx={{ mb: -0.625 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                }
              />
              <CardContent sx={{ p: 2.5, pt: 1, paddingBottom: '17px !important' }}>
                <Grid sx={{ pt: 1 }} item xs={12} container justifyContent="center">
                  <Typography fontSize={15.5}>{props.statusMessage}</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}

export default ApiStatusModal
