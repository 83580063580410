import React, { useMemo, useEffect, Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { fetchJSON } from 'utils/FetchData'
// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, LinearProgress } from '@mui/material'

// project imports
import Header from './Header'
import Sidebar from './Sidebar'
import useConfig from 'hooks/useConfig'
import { useSelector } from 'store'
import { SWRConfig } from 'swr'
import api from '../../utils/api'
import ApiStatusModal from 'generic-component/ApiStatusModal'
import useSelectedVesselStore from 'store/vesselStore'
import LoaderforVesseldata from 'generic-component/LoaderforVesseldata'
import FlowMeterUpdater from './FlowMeterUpdater'

// styles
const Main = styled('main')(({ theme }) => ({
  ...theme.typography.mainContent,
  ...{
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: `100%`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: '100%',
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      width: `100%`,
      padding: '0px',
      marginRight: '0px',
    },
  },
}))

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme()
  const { drawerOpen } = useSelector((state) => state.menu)
  const { container } = useConfig()
  // const [Loading, setLoading] = useState(false)
  // const [error, setError] = useState('')
  const fetchVesselsList = useSelectedVesselStore((state) => state.fetch)
  useEffect(() => {
    fetchVesselsList()
  }, [fetchVesselsList])
  const header = useMemo(
    () => (
      <Toolbar sx={{ boxShadow: '0 4px 20px rgb(0 0 0 / 40%)' }}>
        <Header />
      </Toolbar>
    ),
    []
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          '& .MuiToolbar-root': {
            minHeight: '46px !important',
          },
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}>
        {header}
      </AppBar>
      <Sidebar />

      {/* main content */}

      <Main sx={{ mt: 5.625, mr: 0, p: 0, minHeight: `calc(100vh - 6vh)`, backgroundColor: theme.palette.mode == 'dark' && 'rgb(6, 13, 18)' }} theme={theme} open={drawerOpen}>
        <LoaderforVesseldata>
          <FlowMeterUpdater>
            <Suspense fallback={''}>
              {/* Global SWR config for requests throughout application */}
              <SWRConfig
                value={{
                  revalidateOnFocus: false,
                  refreshInterval: 0,
                  shouldRetryOnError: false,
                  fetcher: async (args) => {
                    const response = await api(args).json()
                    return response
                  },
                }}>
                {container && (
                  <Container maxWidth="lg">
                    {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                    <Outlet />
                  </Container>
                )}
                {!container && (
                  <>
                    {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                    <Outlet />
                  </>
                )}
              </SWRConfig>
            </Suspense>
          </FlowMeterUpdater>
        </LoaderforVesseldata>
      </Main>
    </Box>
  )
}

export default MainLayout
