// routing
import Routes from 'routes'

// project imports
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import RTLLayout from 'ui-component/RTLLayout'
import Snackbar from 'ui-component/extended/Snackbar'
import ThemeCustomization from 'themes'
import { Helmet } from 'react-helmet'
import 'assets/scss/kendo-light.scss'
import 'assets/scss/kendo-dark.scss'
// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext'
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
import useConfig from 'hooks/useConfig'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey('10ca57a75dd3d7c494d9617fedb0612fTz01MzY1MCxFPTE2OTkwOTcxOTYwMzAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')
// ==============================|| APP ||============================== //

const App = () => {
  const { navType } = useConfig()
  return (
    <ThemeCustomization>
      {/* RTL layout */}
      <RTLLayout>
        <NavigationScroll>
          <AuthProvider>
            <>
              <Helmet>
                <body className={`kendo-${navType == 'dark' ? 'dark' : 'light'}-theme`}></body>
              </Helmet>
              <Routes />
              <Snackbar />
            </>
          </AuthProvider>
        </NavigationScroll>
      </RTLLayout>
    </ThemeCustomization>
  )
}

export default App
