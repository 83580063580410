import { lazy } from 'react'

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable'

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')))
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')))
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')))
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')))
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    },
    {
      path: '/reset',
      element: <ResetPassword />,
    },
    {
      path: '/register',
      element: <AuthRegister />,
    },
    {
      path: '/forgot',
      element: <AuthForgotPassword />,
    },
  ],
}

export default LoginRoutes
