// import { CircularProgress, TextField } from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import React, { useCallback, useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import useSelectedVesselStore from '../store/vesselStore';
// import { Typography } from '@mui/material';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// //import "../styles/VesselDropdown.css";

// // const useStyles = makeStyles((theme) => ({
// //   listbox: {
// //     listStyle: "none",
// //     margin: "0px !important",
// //     padding: "0% 0px 0px 0px !important",
// //     //maxHeight: "100vh !important",
// //     overflow: "auto"
// //   }
// // }));

// const AsyncSelect = ({ defaultVessel, handleChange }) => {
//     // const classes = useStyles();
//     const [open, setOpen] = useState(false);
//     const vesselList = useSelectedVesselStore((state) => state.vesselList);
//     console.log(vesselList.length);
//     const filteredVesselList = vesselList.filter((x) => x.status === 'SAT');
//     const loading = open && vesselList.length === 0;
//     const closeDropdown = useCallback(() => setOpen(false), []);
//     const openDropdown = useCallback(() => setOpen(true), []);
//     //const handleClickAway = useCallback(() => setOpen(false), []);
//     return (
//         <ClickAwayListener onClickAway={closeDropdown}>
//             <div style={{ paddingLeft: '3em', paddingTop: '5px' }}>
//                 <Autocomplete
//                     //className={classes.listbox}
//                     multiple={false}
//                     id="free-solo-2-demo"
//                     autoSelect={false}
//                     // popupIcon={SendIcon}
//                     sx={{ width: '500%' }}
//                     open={open}
//                     onOpen={openDropdown}
//                     onClose={closeDropdown}
//                     isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
//                     getOptionLabel={(option) => (!option.vesselName ? ' Loading...' : option.vesselName + ' | IMO: ' + '12345678')}
//                     options={filteredVesselList}
//                     loading={loading}
//                     defaultValue={defaultVessel}
//                     selectOnFocus={false}
//                     disableClearable
//                     onChange={(e, value) => handleChange(value)}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             // style={{ height: -1 }}
//                             label="Search Vessel"
//                             //variant="outlined"
//                             // variant="standard"
//                             size="small"
//                         />
//                     )}
//                     renderOption={(props, option, { selected }) => (
//                         <li {...props}>
//                             {option.vesselName}
//                             <br />
//                             {option.imo}
//                         </li>
//                     )}
//                 />
//             </div>
//         </ClickAwayListener>
//     );
// };

// export default React.memo(AsyncSelect);

import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import useSelectedVesselStore from '../store/vesselStore'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, Popper, Autocomplete, TextField } from '@mui/material'

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'

// project imports
import Transitions from 'ui-component/extended/Transitions'

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons'
import { shouldForwardProp } from '@mui/system'

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 1px',
  },
}))

const OutlineInputStyle = styled(Autocomplete, { shouldForwardProp })(({ theme }) => ({
  width: 350,
  paddingLeft: '3em',
  paddingTop: '5px',
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: 0,
    width: '100%',
    marginLeft: 4,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
  },
}))

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
  },
}))

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ open, openDropdown, closeDropdown, loading, defaultVessel, handleChange, filteredVesselList, popupState }) => {
  const theme = useTheme()

  return (
    <div style={{ display: 'flex' }}>
      <OutlineInputStyle
        multiple={false}
        id="free-solo-2-demo"
        autoSelect={false}
        open={open}
        onOpen={openDropdown}
        onClose={closeDropdown}
        isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
        getOptionLabel={(option) => (!option.vesselName ? ' Loading...' : option.vesselName + ' | IMO: ' + '12345678')}
        options={filteredVesselList}
        loading={loading}
        defaultValue={defaultVessel}
        selectOnFocus={false}
        disableClearable
        onChange={(e, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            // style={{ height: -1 }}
            label="Search Vessel"
            //variant="outlined"
            // variant="standard"
            size="small"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option.vesselName}
            <br />
            {option.imo}
          </li>
        )}
      />

      <Box sx={{ ml: 2 }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
            color: theme.palette.orange.dark,
            '&:hover': {
              background: theme.palette.orange.dark,
              color: theme.palette.orange.light,
            },
          }}
          {...bindToggle(popupState)}>
          <IconX stroke={1.5} size="1.3rem" />
        </Avatar>
      </Box>
    </div>
  )
}

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
}

// ==============================|| SEARCH INPUT ||============================== //

const AsyncSelect = ({ defaultVessel, handleChange }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const vesselList = useSelectedVesselStore((state) => state.vesselList)
  const filteredVesselList = vesselList
  const loading = open && vesselList.length === 0
  const closeDropdown = useCallback(() => setOpen(false), [])
  const openDropdown = useCallback(() => setOpen(true), [])
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                  <IconSearch stroke={1.5} size="1.2rem" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                      <Card
                        sx={{
                          background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none',
                          },
                        }}>
                        <Box sx={{ px: 0.875, pt: 2.5, pb: 0.5 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch
                                open={open}
                                openDropdown={openDropdown}
                                closeDropdown={closeDropdown}
                                loading={loading}
                                defaultVessel={defaultVessel}
                                filteredVesselList={filteredVesselList}
                                handleChange={handleChange}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { md: 'block' } }}>
        <OutlineInputStyle
          multiple={false}
          id="free-solo-2-demo"
          autoSelect={false}
          open={open}
          onOpen={openDropdown}
          onClose={closeDropdown}
          isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
          getOptionLabel={(option) => (!option.vesselName ? ' Loading...' : option.vesselName + ' | IMO: ' + option.imo)}
          options={filteredVesselList}
          loading={loading}
          defaultValue={defaultVessel}
          selectOnFocus={false}
          disableClearable
          onChange={(e, value) => handleChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              // style={{ height: -1 }}
              label="Search Vessel"
              //variant="outlined"
              // variant="standard"
              size="small"
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {option.vesselName}
              <br />
              {option.imo}
            </li>
          )}
        />
      </Box>
    </>
  )
}

export default AsyncSelect
