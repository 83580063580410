import { authService } from 'services/auth-service'
import { authHeader } from 'services/auth-header'
import { useNavigate } from 'react-router-dom'
//import { useNavigate } from 'react-router-dom'
export async function fetchJSON(url, method = 'GET', body = null) {
  // const navigate = useNavigate()
  const options = {
    method,
    headers: {
      Authorization: authHeader().Authorization,
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const errorMessage = await response.text()
      // Handle 403 Forbidden by navigating to the /login route
      if (response.status === 403) {
        //  window.location.reload(true)
      }
      throw new Error(
        JSON.stringify({
          status: response.status,
          message: errorMessage,
        })
      )
    }
    const data = await response.json()
    return { data, error: null, isLoading: false }
  } catch (error) {
    return { data: null, error: error.message, isLoading: false }
  }
}
