import React from 'react'
import { authHeader } from './auth-header'
import { Navigate, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Error404 from 'generic-component/Error404'

const AuthGaurd = (props) => {
  const auth = authHeader().Authorization
  const pageAccess = JSON.parse(localStorage.getItem('authATLASBO'))
  const location = useLocation()
  var path = location.pathname
  let isJwtExpired

  if (auth) {
    const decoded = jwtDecode(auth)
    isJwtExpired = decoded.exp > Date.now() / 1000
  }

  var JwtExpCheckFlag = auth ? (isJwtExpired ? true : false) : false

  if (auth && JwtExpCheckFlag) {
    if (pageAccess?.accessClaims.length == 0) {
      return <Error404 />
    } else {
      var isUrlPresent = pageAccess?.accessClaims.some((x) => x.url == path)
      return isUrlPresent ? props.children : <Error404 />
    }
  }
  return <Navigate to="/login" />
}

export default AuthGaurd
