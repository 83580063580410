import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, Stack, useMediaQuery, Divider, Typography, Button } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
// project imports
import MenuList from './MenuList'
import LogoSection from '../LogoSection'
import MenuCard from './MenuCard'
import { openDrawer } from 'store/slices/menu'
import { useDispatch, useSelector } from 'store'
import Chip from 'ui-component/extended/Chip'
import ClientLogo from 'assets/images/icons/ClientLogo/em_logo.svg'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { drawerOpen } = useSelector((state) => state.menu)

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box sx={{ display: 'flex', py: 0.375, pl: 3, pb: 0, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    []
  )

  const clientLogo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box sx={{ display: 'flex', py: 1.5, pl: 2.5, mx: 'auto' }}>
          <img
            alt="Logo"
            id="clientLogo"
            src={ClientLogo}
            style={{
              height: '60px',
              width: '150px',
            }}
          />
        </Box>
      </Box>
    ),
    []
  )

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 56px)',
          paddingLeft: '6px',
          paddingRight: '6px',
        }}>
        <Box sx={{ mt: 2 }}>
          <MenuList />
        </Box>
        {!matchUpMd && (
          <Box sx={{ textAlign: 'end' }}>
            <Button
              sx={{ py: 0.25 }}
              onClick={() => {
                localStorage.removeItem('authATLASBO')
                navigate('/login', { replace: true })
              }}
              variant={'contained'}
              startIcon={<PowerSettingsNewIcon />}
              size="small">
              Sign-Out
            </Button>
          </Box>
        )}
      </PerfectScrollbar>
    ),
    // eslint-disable-next-line
    [matchUpMd]
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: 210,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '0px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit">
        {drawerOpen && logo}
        <Divider sx={{ mt: 0, mb: 0 }} />
        {drawerOpen && drawer}
        {/* <Divider sx={{ mt: 0, mb: 0 }} /> */}
        {/* {drawerOpen && clientLogo} */}
      </Drawer>
    </Box>
  )
}

Sidebar.propTypes = {
  window: PropTypes.object,
}

export default memo(Sidebar)
