import { LinearProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import useSelectedVesselStore from 'store/vesselStore'
import { fetchJSON } from 'utils/FetchData'
import ApiStatusModal from 'generic-component/ApiStatusModal'
const FlowMeterUpdater = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authATLASBO'))
  var userId = userInfo?.user?.userId
  const [Loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const selectedVessel = useSelectedVesselStore((state) => state.selectedVessel)
  const updateFlowmeter = useSelectedVesselStore((state) => state.updateFlowMeter)
  useEffect(() => {
    async function getAsyncData() {
      const { data, error, loading } = await fetchJSON(`${window.Constants.BaseUrl}/Home/getAllFlowmeters?vesselId=${selectedVessel?.vesselId}&userId=${userId}`)
      if (error) {
        setError('Error occured while getting data.')
        setLoading(false)
      }
      if (data?.data) {
        const m_data = data?.data.map((item) => ({
          value: item.flowmeterCode,
          label: item.flowmeterName,
          ...item,
        }))
        updateFlowmeter(m_data)
        setLoading(false)
        // localStorage.setItem('flowmeters', JSON.stringify(m_data))
      }
    }
    getAsyncData()
  }, [selectedVessel])

  return <>{Loading ? <LinearProgress /> : <>{error ? <ApiStatusModal status={'Error'} statusMessage={'Error occured while getting data'} /> : <>{props.children}</>}</>}</>
}

export default FlowMeterUpdater
