// material-ui
import { useCallback } from 'react'
import { useTheme, makeStyles } from '@mui/styles'
import { Avatar, Box, Hidden, Button, Tooltip, Typography, useMediaQuery, CircularProgress, LinearProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { fetchJSON } from 'utils/FetchData'
import ApiStatusModal from 'generic-component/ApiStatusModal'
// project imports
import { useState, useEffect } from 'react'
import moment from 'moment'
import LogoSection from '../LogoSection'
import ThemeSection from './ThemeSection'
import { useDispatch, useSelector } from 'store'
import { Link } from 'react-router-dom'
import SeakerLogo from 'assets/images/icons/SeakerLogo.svg'
import { openDrawer } from 'store/slices/menu'
import { IconMenu2 } from '@tabler/icons'
import { Person } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'generic-component/AsyncSelect'
import useSelectedVesselStore from 'store/vesselStore'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const TimeBar = () => {
  const [time, setTime] = useState({ currentTime: Date.now() })

  useEffect(() => {
    let timer = setInterval(() => _tick(), 1000)
    const _tick = () => {
      setTime({ currentTime: Date.now() })
    }

    return () => {
      clearInterval(timer)
    }
  }, [time.currentTime])
  return (
    <div style={{ padding: '2px 4px 5px 4px', borderImage: 'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%) 1/1/0 stretch', borderBottomStyle: 'solid', borderWidth: '0px 0px 2px 0px' }}>
      <Typography variant="h5" style={{ fontSize: '1rem', fontWeight: 450, paddingTop: 8 }}>
        Local DateTime : {moment(time.currentTime).format('DD-MM-YYYY HH:mm:ss')}
      </Typography>
    </div>
  )
}

const HeaderWrapper = () => {
  const theme = useTheme()
  // const [Loading, setLoading] = useState(true)
  // const [error, setError] = useState('')
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { drawerOpen } = useSelector((state) => state.menu)
  const updateSelectedVessel = useSelectedVesselStore((state) => state.update)
  const selectedVessel = useSelectedVesselStore((state) => state.selectedVessel)
  const updateFlowmeter = useSelectedVesselStore((state) => state.updateFlowMeter)
  let user = JSON.parse(localStorage.getItem('authATLASBO'))
  let firstName = user.user.firstName ?? 'No'
  // let lastName = user.user.lastName ?? 'Name'
  let flName = Array.from(firstName)[0]
  const handleVesselSelectionChange = useCallback(
    (value) => {
      updateSelectedVessel(value)
    },
    [updateSelectedVessel]
  )
  // useEffect(() => {
  //   async function getAsyncData() {
  //     const { data, error, loading } = await fetchJSON(`${window.Constants.BaseUrl}/Home/getAllFlowmeters?imo=${selectedVessel?.imo}`)
  //     if (error) {
  //       setError('Error occured while getting data.')
  //       setLoading(false)
  //     }
  //     if (data?.data) {
  //       setLoading(false)
  //       const m_data = data?.data.map((item) => ({
  //         value: item.flowmeterCode,
  //         label: item.flowmeterName,
  //         ...item,
  //       }))
  //       updateFlowmeter(m_data)
  //       // localStorage.setItem('flowmeters', JSON.stringify(m_data))
  //     }
  //   }
  //   if (selectedVessel?.imo) {
  //     getAsyncData()
  //   }
  //   return () => {}
  // }, [selectedVessel])
  function DropDownOrSearchRender() {
    if (location.pathname == '/fleet-overview' || location.pathname == '/reconciliation-report') {
      return ''
    } else {
      return <AsyncSelect defaultVessel={selectedVessel} handleChange={handleVesselSelectionChange} />
    }
  }

  return (
    <>
      {/* {Loading ? (
        <LinearProgress />
      ) : (
        <>
          {error ? (
            <ApiStatusModal status={'Error'} statusMessage={'Error occured while getting data'} />
          ) : ( */}
      <>
        {/* logo & toggler button */}
        <Box
          sx={{
            width: 228,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
              width: 'auto',
            },
          }}>
          <Box component="span" sx={{ display: { xs: 'none', md: 'contents' }, flexGrow: 1 }}>
            <img
              alt="Logo"
              id="navigationLogo"
              //src={SeakerLogo}
              src={theme.palette.mode === 'dark' ? '/Atlas_Full_DarkTheme.svg' : '/Atlas_Full_LightTheme.svg'}
              style={{
                height: '1.45rem',
                marginTop: '0.3rem',
                marginLeft: '1.4rem',
              }}
            />
          </Box>
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            marginLeft: { xs: 0, md: '-2.5rem' },
            marginTop: { xs: 0.25, md: 0.625 },
            height: 35,
            width: 35,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
              color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color="inherit">
          <IconMenu2 stroke={1.5} size="1.3rem" />
        </Avatar>
        <Box sx={{ ml: matchDownSM ? 1 : 4, mt: matchDownSM && 0.3, width: matchDownSM && 240 }}>
          <DropDownOrSearchRender />
          {/* <Typography variant="h4" textTransform={'uppercase'}>
          {window.Constants.vesselName} ({window.Constants.Imo})
        </Typography> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        {/* <TimeBar /> */}
        &nbsp;
        <ThemeSection />
        {!matchDownSM && (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title={`Logout ${firstName}`} placement="bottom-start">
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  fontSize: '1rem',
                  cursor: 'pointer',
                  background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                  color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
                }}
                onClick={() => {
                  localStorage.removeItem('authATLASBO')
                  navigate('/login', { replace: true })
                }}>
                {flName}
              </Avatar>
            </Tooltip>
          </>
        )}
      </>
      {/* )}
        </>
      )} */}
    </>
  )
}

export default HeaderWrapper
