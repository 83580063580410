import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from '../services/AuthGaurd'

// sample page routing
const FlowmeterAnalysis = Loadable(lazy(() => import('views/LiveAnalysis/FlowmeterFeed/FlowmeterAnalysis')))
const HistoricAnalysis = Loadable(lazy(() => import('views/HistoricAnalysis/HistoricAnalysis')))
const TelemetryAnalysis = Loadable(lazy(() => import('views/LiveAnalysis/TelemetryFeed/TelemetryAnalysis')))
const EngineAnalysis = Loadable(lazy(() => import('views/LiveAnalysis/EngineRoomFeed/EngineRoomAnalysis')))
const FuelSummary = Loadable(lazy(() => import('views/FuelAnalysis/SummaryReport/Report')))
const FuelDetail = Loadable(lazy(() => import('views/FuelAnalysis/DetailReport/DReport')))
const PortInfo = Loadable(lazy(() => import('views/PortDetails')))
const PortInfo2 = Loadable(lazy(() => import('views/EventTracker_v2')))
const EventReport = Loadable(lazy(() => import('views/EventReport/EReport')))
const ConsumptionReport = Loadable(lazy(() => import('views/FuelAnalysis/ConsumptionReport/CReport')))
const EngineSummary = Loadable(lazy(() => import('views/EngineRoomAnalysis/SummaryReport/Report')))
const EngineDetail = Loadable(lazy(() => import('views/EngineRoomAnalysis/DetailReport/DReport')))
const Alarm = Loadable(lazy(() => import('views/Alarm/AReport')))
const ConsCorrection = Loadable(lazy(() => import('views/ConsumptionCorrection/CCorrection')))
const AlarmView = Loadable(lazy(() => import('views/AlarmScheduler')))
const FleetOverView = Loadable(lazy(() => import('views/FleetOverview')))
const VesselOverView = Loadable(lazy(() => import('views/VesselOverview')))
const ReconciliationOverView = Loadable(lazy(() => import('views/Reconciliation')))
const VesselTracker = Loadable(lazy(() => import('views/VesselTracker')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: '/telemetry-analysis',
    //   element: <TelemetryAnalysis />,
    // },
    {
      path: '/',
      element: <FlowmeterAnalysis />,
    },
    {
      path: '/engine-analysis',
      element: <EngineAnalysis />,
    },
    // {
    //   path: '/historic-analysis',
    //   element: <HistoricAnalysis />,
    // },
    {
      path: '/summary',
      element: <FuelSummary />,
    },
    {
      path: '/detail',
      element: <FuelDetail />,
    },
    // {
    //   path: '/engine-summary',
    //   element: <EngineSummary />,
    // },
    {
      path: '/engine-detail',
      element: <EngineDetail />,
    },
    {
      path: '/consumption',
      element: <ConsumptionReport />,
    },
    {
      path: '/event-tracker',
      element: <PortInfo />,
    },
    {
      path: '/event-report',
      element: <EventReport />,
    },
    {
      path: '/event-trackerv2',
      element: <PortInfo2 />,
    },
    {
      path: '/cons-correction',
      element: <ConsCorrection />,
    },
    {
      path: '/alarm',
      element: <Alarm />,
    },
    {
      path: '/alarm-view',
      element: <AlarmView />,
    },
    {
      path: '/fleet-overview',
      element: <FleetOverView />,
    },
    {
      path: '/vessel-overview',
      element: <VesselOverView />,
    },
    {
      path: '/reconciliation-report',
      element: <ReconciliationOverView />,
    },
    {
      path: '/vessel-tracker',
      element: <VesselTracker />,
    },
  ],
}

export default MainRoutes
