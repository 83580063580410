// TODO: Convert to TS

import ky from 'ky'
import { authHeader } from '../../src/services/auth-header'
import { authService } from '../../src/services/auth-service'

export default ky.extend({
  retry: 0,
  timeout: 120000,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', authHeader().Authorization)
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 403) {
          // Get new token and refresh token upon encountering 403 error
          //  authService.logout()
          return ky(request)
        }
      },
    ],
  },
})
