import api from 'utils/api'
import { create } from 'zustand'
const data = [
  {
    legnumber: '20/2021/02',
    portCallId: 4626,
    status: 'SAT',
    vessel_Object_ID: 865054,
    vessel_ID: 865054,
    vesselName: 'Alpha Vessel',
    imo: 1234567,
  },
  {
    legnumber: '010E/03',
    portCallId: 4567,
    status: 'SAT',
    vessel_Object_ID: 109683,
    vessel_ID: 109683,
    vesselName: 'Bravo Vessel',
    imo: 1234567,
  },
  {
    legnumber: '10/21/01',
    portCallId: 4741,
    status: 'SAT',
    vessel_Object_ID: 865053,
    vessel_ID: 865053,
    vesselName: 'Charlie Vessel',
    imo: 1234567,
  },
]
const useSelectedVesselStore = create((set) => ({
  error: null,
  loading: true,
  vesselList: [],

  selectedVessel: {
    vesselID: undefined,
    vesselObjectID: undefined,
    vesselUIId: undefined,
    vesselName: '',
    imo: '',
    portCallId: undefined,
    legnumber: '',
  },

  flowmeterList: [],
  updateFlowMeter: (args) =>
    set({
      flowmeterList: args,
    }),

  update: (args) =>
    set({
      selectedVessel: args,
    }),

  fetch: async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('authATLASBO'))
      var userId = userInfo?.user?.userId
      const response = await api(`${window.Constants.BaseUrl}/VesselInfo/getUserVessels?userId=${userId}`).json()
      set({ error: null, loading: false, vesselList: response.data })
    } catch (error) {
      set({
        error: error,
        loading: false,
        vesselList: [],
      })
    }
  },
}))

export default useSelectedVesselStore
