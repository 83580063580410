import React, { useEffect } from 'react'
import useSelectedVesselStore from 'store/vesselStore'
import HeaderWrapper from './HeaderWrapper'

const Header = () => {
  const vesselList = useSelectedVesselStore((state) => state.vesselList)
  const updateSelectedVessel = useSelectedVesselStore((state) => state.update)

  useEffect(() => {
    updateSelectedVessel(vesselList[0])
  }, [vesselList])
  return <HeaderWrapper />
}

export default Header
