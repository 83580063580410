import { Link as RouterLink } from 'react-router-dom'

// material-ui
import { Link, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import { DASHBOARD_PATH } from 'config'
import Logo from 'ui-component/Logo'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const theme = useTheme()
  const LogotitleColor = theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]
  return (
    <>
      <Link sx={{ mt: '3px', mb: 1 }} component={RouterLink} to={DASHBOARD_PATH}>
        {/* <Logo />  */}
        <Box component="span" sx={{ display: 'contents', flexGrow: 1 }}>
          <img
            alt="Logo"
            id="navigationLogo"
            src={theme.palette.mode === 'dark' ? '/Atlas_Full_DarkTheme.svg' : '/Atlas_Full_LightTheme.svg'}
            style={{
              height: '1.45rem',
              marginTop: '0.3rem',
              marginLeft: '1.7rem',
            }}
          />
        </Box>
      </Link>
    </>
  )
}

export default LogoSection
